import { getAuth } from "firebase/auth";
import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app";

import useGetConfig from "../../../config/hooks/useGetConfig";

let app: FirebaseApp;

export default function useGetFirebaseAuth() {
  const { config } = useGetConfig();

  if (!app) app = initializeApp(config?.firebase as FirebaseOptions);

  return getAuth(app);
}
