import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";

import en from "./translations/en";
import pt from "./translations/pt";
import de from "./translations/de";
import fr from "./translations/fr";
import es from "./translations/es";

const resources = {
  GB: { translation: en },
  US: { translation: en },

  BR: { translation: pt },

  DE: { translation: de },
  AT: { translation: de },

  FR: { translation: fr },

  CO: { translation: es },
  MX: { translation: es },
};

const options = {
  resources,
  fallbackLng: "GB",
  interpolation: { escapeValue: false },
  debug: !!process.env.REACT_APP_USE_CONFIG,
  detection: { lookupQuerystring: "region" },
};

i18n.use(LanguageDetector).use(initReactI18next).init(options);

export default i18n;
