import { Outlet } from "react-router-dom";
import { Box, Center, makeBox, Stack } from "@seneca/senekit";

import { ReactComponent as seneca } from "../../assets/logo/seneca.svg";

const SenecaLogo = makeBox(seneca);

export default function AuthMobileOutlet() {
  return (
    <Stack>
      <Center>
        <SenecaLogo width="220px" />
      </Center>

      <Box
        flex="1"
        width="100%"
        height="70vh"
        display="flex"
        padding="xxlarge"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
      >
        <Outlet />
      </Box>
    </Stack>
  );
}
