export default function errorTranslator(error: string): string {
  if (error === "auth/network-request-failed") {
    return "Looks like there is no internet connection.";
  }

  if (error === "auth/popup-blocked") {
    return "The popup is blocked by your browser. Please unblock pop ups.";
  }

  if (error === "auth/popup-closed-by-user") {
    return "The popup has been closed before finalizing the connection, please try again.";
  }

  if (error === "auth/cancelled-popup-request") {
    return "Multiple popups triggered. Please close unused pop ups.";
  }

  if (error === "auth/user-cancelled") {
    return "User denied access. Please grant permission to Seneca Learning to continue.";
  }

  if (error === "auth/auth-domain-config-required") {
    return "Oops.. Looks like is something wrong with authentication domain configuration.";
  }

  if (error === "auth/operation-not-supported-in-this-environment") {
    return "The operation is not supported in the environment your application is running on";
  }

  if (error === "auth/unauthorized-domain") {
    return "The app domain is not authorized.";
  }

  if (error === "auth/account-exists-with-different-credential") {
    return "Looks like your account was created using a different method. Try another way of logging in.";
  }

  return "An unknown error has occurred.";
}
