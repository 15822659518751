import { useTranslation } from "react-i18next";
import { Stack, Center, Box, makeBox, ButtonLink } from "@seneca/senekit";

import { ReactComponent as seneca } from "../../assets/logo/seneca.svg";

const SenecaLogo = makeBox(seneca);

export default function RedirectToSenecaPlatformPage() {
  const { t } = useTranslation();

  return (
    <Stack>
      <Center>
        <SenecaLogo width="220px" />
      </Center>

      <Box
        flex="1"
        width="100%"
        height="70vh"
        display="flex"
        padding="xxlarge"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
      >
        <ButtonLink
          newTab={false}
          fill="outlined"
          to="https://app.senecalearning.com/"
        >
          {t("goToSenecaLearning")}
        </ButtonLink>
      </Box>
    </Stack>
  );
}
