import { createBrowserRouter } from "react-router-dom";

import RedirectToSenecaPlatformPage from "../features/fallback/RedirectToSenecaPlatformPage";
import AuthMobileiOSPage from "../features/authMobile/ios/page/AuthMobileiOSPage";
import AuthMobileOutlet from "../features/authMobile/AuthMobileOutlet";
import RootOutlet from "../features/RootOutlet";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <RootOutlet />,
    children: [
      { index: true, element: <RedirectToSenecaPlatformPage /> },
      {
        path: "/",
        element: <AuthMobileOutlet />,
        children: [{ path: "ios", element: <AuthMobileiOSPage /> }],
      },
    ],
    errorElement: <RedirectToSenecaPlatformPage />,
  },
]);

export default routes;
