import { onAuthStateChanged, User, UserCredential } from "firebase/auth";
import { createContext, ReactElement, useEffect, useState } from "react";

import useGetFirebaseAuth from "./hooks/useGetFirebaseAuth";
import useGetAuthMethods from "./hooks/useGetAuthMethods";
import { AuthProvider, ContextState } from "./types";

export const AuthContext = createContext<ContextState | undefined>(undefined);

type Props = { children: ReactElement };

export default function AuthContextProvider({ children }: Props) {
  const auth = useGetFirebaseAuth();
  const { signInWithSSO, signOut } = useGetAuthMethods();

  const [isSignedIn, setIsSignedIn] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [userCredentials, setUserCredentials] = useState<UserCredential>();

  useEffect(() => onAuthStateChanged(auth, setUser), [auth]);

  useEffect(() => {
    setIsSignedIn(!!user);
    if (user !== undefined) setIsLoading(false);
  }, [user]);

  async function signIn(provide: AuthProvider) {
    setIsLoading(true);
    const response = await signInWithSSO(provide);

    if (response.status === "success") {
      setUserCredentials(response.result.user);
    }

    if (response.status === "error") {
      setErrorMessage(response.result?.error?.message);
    }

    setIsLoading(false);

    return response;
  }

  const contextValue = {
    user,
    isLoading,
    isSignedIn,
    errorMessage,
    userCredentials,
    signIn,
    signOut,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}
