import { User, UserCredential } from "firebase/auth";

export enum AuthStatus {
  success = "success",
  error = "error",
}

export type AuthSuccess = { user: UserCredential };
export type AuthError = { error: { code: string; message: string } };

export type SignInSuccess = {
  status: AuthStatus;
  result: AuthSuccess;
};

export type SignInError = {
  status: AuthStatus;
  result: AuthError;
};

export type SignInResponse = SignInSuccess | SignInError;

export type ContextState = {
  user: User | null;
  isLoading: boolean;
  isSignedIn: boolean;
  errorMessage?: string;
  userCredentials?: UserCredential;
  signOut: () => Promise<void>;
  signIn: (authProvider: AuthProvider) => Promise<SignInResponse>;
};

export type AuthProvider = "google" | "microsoft";
