import { createPortal } from "react-dom";
import { makeSeneKitProvider, ThemeProvider, themes } from "@seneca/senekit";
import { Outlet, Link, NavLink, useSearchParams } from "react-router-dom";

import AppBackground from "./components/AppBackground";

const SeneKitProvider = makeSeneKitProvider({ createPortal, Link, NavLink });

export default function RootOutlet() {
  const [searchParams] = useSearchParams();

  const isDarkTheme = searchParams.get("isDarkTheme") === "true";
  const theme = isDarkTheme ? themes.free.dark : themes.free.light;

  return (
    // @ts-ignore fix-in-senekit
    <SeneKitProvider value={{ createPortal, Link, NavLink }}>
      <ThemeProvider theme={theme}>
        <AppBackground>
          <Outlet />
        </AppBackground>
      </ThemeProvider>
    </SeneKitProvider>
  );
}
