import { FirebaseError } from "firebase/app";
import { signOut as firebaseSignOut, signInWithPopup } from "firebase/auth";

import useGetFirebaseAuth from "../hooks/useGetFirebaseAuth";
import errorTranslator from "../helpers/errorTranslator";
import { AuthProvider, AuthStatus } from "../types";
import providers from "../providers";

export default function useGetAuthMethods() {
  const auth = useGetFirebaseAuth();

  async function signInWithSSO(authProvider: AuthProvider) {
    try {
      const provider = providers[authProvider].setCustomParameters({
        prompt: "select_account",
      });

      const response = await signInWithPopup(auth, provider);

      return { status: AuthStatus.success, result: { user: response } };
    } catch (error) {
      if (error instanceof FirebaseError) {
        return {
          status: AuthStatus.error,
          result: {
            error: {
              code: error.code as string,
              message: errorTranslator(error.code),
            },
          },
        };
      }

      throw error;
    }
  }

  async function signOut() {
    await firebaseSignOut(auth);
  }

  return { signInWithSSO, signOut };
}
