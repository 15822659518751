import { AuthProvider } from "../../../../../contexts/auth/types";

export default function makeRedirectUrl({
  credentials,
  isNewUser,
  provider,
}: {
  credentials: string;
  isNewUser: boolean;
  provider: AuthProvider;
}) {
  const queryParams = `?credentials=${credentials}&provider=${provider}&isNewUser=${isNewUser}`;

  return `senecalearning://main${queryParams}`;
}
