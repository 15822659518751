import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams, useNavigate } from "react-router-dom";
import { getAdditionalUserInfo, OAuthProvider } from "firebase/auth";
import { Alert, Button, Inline, makeBox, Stack } from "@seneca/senekit";

import { ReactComponent as microsoft } from "../../../../assets/icons/sso/microsoft.svg";
import { ReactComponent as google } from "../../../../assets/icons/sso/google.svg";

import closeWindowOnFocusOutEventListener from "./utils/closeWindowOnFocusOutEventListener";
import { isAuthSuccess } from "../../../../contexts/auth/utils/isAuthSuccess";
import { AuthProvider } from "../../../../contexts/auth/types";
import isValidAuthProvider from "./utils/isValidAuthProvider";
import useAuth from "../../../../contexts/auth/hooks/useAuth";
import navigateDeepLink from "./utils/navigateDeepLink";
import makeRedirectUrl from "./utils/makeRedirectUrl";

const MicrosoftIcon = makeBox(microsoft);
const GoogleIcon = makeBox(google);

const providers = {
  google: {
    Icon: <GoogleIcon display="block" width="20px" height="20px" />,
    displayName: "Google",
  },
  microsoft: {
    Icon: <MicrosoftIcon display="block" width="20px" height="20px" />,
    displayName: "Microsoft",
  },
};

export default function AuthMobileiOSPage() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const provider = searchParams.get("provider") as AuthProvider;

  const [redirectUrl, setRedirectUrl] = useState("");
  const [showRedirectButton, setShowRedirectButton] = useState(false);

  const { signIn, isLoading, errorMessage } = useAuth();

  useEffect(() => {
    if (!isValidAuthProvider(provider)) navigate("/");
  }, [provider, navigate]);

  async function handleAuthUser() {
    const authResponse = await signIn(provider);

    if (isAuthSuccess(authResponse)) {
      const { user: userCredentials } = authResponse.result;
      const credential = OAuthProvider.credentialFromResult(userCredentials);
      const credentialStringified = JSON.stringify(credential?.toJSON());
      const userInfo = getAdditionalUserInfo(userCredentials);

      closeWindowOnFocusOutEventListener();

      const url = makeRedirectUrl({
        provider,
        isNewUser: !!userInfo?.isNewUser,
        credentials: credentialStringified,
      });

      setShowRedirectButton(true);
      setRedirectUrl(url);

      navigateDeepLink(url);
    }
  }

  function handleRedirectToApp() {
    navigateDeepLink(redirectUrl);
  }

  if (!isValidAuthProvider(provider)) return null;

  return (
    <Stack space="xxxlarge" align="center">
      {!showRedirectButton && (
        <Button fill="outlined" loading={isLoading} onClick={handleAuthUser}>
          <Inline space="large" alignItems="center" flexWrap="nowrap">
            {providers[provider].Icon}
            <Inline space="small">
              {t("continueWithProvider", {
                provider: providers[provider].displayName,
              })}
            </Inline>
          </Inline>
        </Button>
      )}

      {showRedirectButton && (
        <Button onClick={handleRedirectToApp}>
          {t("redirectBackToTheApp")}
        </Button>
      )}

      {errorMessage && !isLoading && <Alert tone="error">{errorMessage}</Alert>}
    </Stack>
  );
}
