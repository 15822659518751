import React from "react";
import { Helmet } from "react-helmet";
import { createGlobalStyle } from "styled-components";
import { Box, useBackgroundColor } from "@seneca/senekit";

type GlobalStyleProps = {
  backgroundColor: string;
};

const GlobalStyle = createGlobalStyle`
  body {
    background: ${(props: GlobalStyleProps) => `${props.backgroundColor}`};
  }
`;

type Props = {
  children: React.ReactNode;
};

export default function AppBackground({ children }: Props) {
  const backgroundColor = useBackgroundColor("HEX");

  return (
    <>
      <Helmet>
        <meta name="theme-color" content={backgroundColor} />
        <meta name="msapplication-navbutton-color" content={backgroundColor} />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content={backgroundColor}
        />
      </Helmet>
      <GlobalStyle backgroundColor={backgroundColor} />
      <Box width="100%">{children}</Box>
    </>
  );
}
