import React from "react";
import { RouterProvider } from "react-router-dom";

import AuthContextProvider from "./contexts/auth/AuthContextProvider";
import Routes from "./routes";

import "./App.css";

function App() {
  return (
    <React.StrictMode>
      <AuthContextProvider>
        <RouterProvider router={Routes} />
      </AuthContextProvider>
    </React.StrictMode>
  );
}

export default App;
